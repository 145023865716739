import React, { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useLocation, useHistory } from 'react-router-dom';
import { useOrder } from '../hooks/order';

const TableProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const { table, card } = useOrder();

  useEffect(() => {
    const tableDate = sessionStorage.getItem('@BSFOOD:table_session');
    const cardDate = sessionStorage.getItem('@BSFOOD:card_session');

    if (tableDate && table.tableNumber > 0) {
      if (tableDate && typeof tableDate === 'string') {
        const { hours } = DateTime.fromMillis(Number(tableDate))
          .diffNow('hours')
          .toObject();

        if (hours && hours < -1) {
          sessionStorage.removeItem('@BSFOOD:table_session');
          history.push('/table', { expired: true });
        }
      }
    }

    if (card.cardNumber > 0 && cardDate) {
      if (cardDate && typeof cardDate === 'string') {
        const { hours } = DateTime.fromMillis(Number(cardDate))
          .diffNow('hours')
          .toObject();

        if (hours && hours < -1) {
          sessionStorage.removeItem('@BSFOOD:card_session');
          history.push('/table', { expired: true });
        }
      }
    }
  }, [history, location, card, table]);

  return <>{children}</>;
};

export default TableProvider;
