const theme = {
  dark: false,
  palette: {
    primary: '#db1622',
    primary_contrast: '#fff',
    primary_light: '#f6adb2',
    primary_transparency: '#db162280',
    secondary: '#fcd661',
    secondary_light: '#fdeaaf',
    white: '#fff',
    white_transparency: '#fff6',
    green: '#27ae60',
    green_light: '#2ecc71',
    control: '#aaa',
    control_light: '#fdfdfd',
    control_lighter: '#f8f8f8',
    info: '#3172b7',
    error: '#c53030',
    error_transparency: '#c5303050',
    success: '#27ae60',
    success_transparency: '#27ae6050',
    badge: '#e74c3c',
    light_text: '#ccc',
    light_text_2: '#b5b5b5',
    black: '#1b1c1e',
    black_transparency: '#1b1c1e20',
    facebook: '#4c69ba',
    instagram: '#e1306c',
    whatsapp: '#25d366',
    background: '#f9f7f7',
    background_white: '#fff',
    background_dark: '#e3dada',
    background_info: '#fafafa',
    background_error: '#fafafa',
    background_success: '#fafafa',
    background_secondary: '#fdfdfb',
    outline: 'rgba(0, 0, 0, .1)',
    scrollBar: {
      track: '#f1f1f1',
      thumb: '#8d9396',
      thumb_hover: '#787f82',
    },
    border: '#f4f4f4',
    shimmer_gradient:
      'linear-gradient(90deg,rgba(238, 238, 238, 1) 12%,rgba(210, 210, 210, 1) 50%,rgba(238, 238, 238, 1) 88%)',
    sale_price: '#34495e',
    modal_backdrop: 'rgba(255, 255, 255, 0.75)',
    shimmer_background: '#eee',
    shimmer_color: '#dfdfdf',
  },
  shadow: {
    box_shadow: '3px 3px 8px 0 rgba(0, 0, 0, 0.1)',
    box_shadow_hover: '3px 3px 8px 0 rgba(0, 0, 0, 0.2)',
    box_shadow_large: '6px 6px 24px 0 rgba(0, 0, 0, 0.6)',
    box_shadow_small: '2px 2px 4px 0 rgba(0, 0, 0, 0.1)',
    box_shadow_float: '-1px 2px 3px rgba(0, 0, 0, 0.2)',
    box_shadow_modal: '3px 3px 8px 0 rgb(0 0 0 / 30%)',
  },
};

export default theme;
