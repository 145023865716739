import lightTheme from '../styles/themes/light.theme';
import darkTheme from '../styles/themes/dark.theme';
import { getMetaTag } from './html';

const hexToRgb = (hex: string) => {
  const normalized = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
  );

  return normalized
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.map(x => parseInt(x, 16));
};

const rgbToHex = (r: number, g: number, b: number) => {
  const colorsArray = [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  });

  return `#${colorsArray.join('')}`;
};

const lerp = (a: number, b: number, u: number) => (1 - u) * a + u * b;

export const mergeColors = (
  color: string,
  secondColor: string,
  percentage: number,
): string => {
  const converted = hexToRgb(color) || [];
  const convertedSecond = hexToRgb(secondColor) || [];

  const r = Math.round(lerp(converted[0], convertedSecond[0], percentage));
  const g = Math.round(lerp(converted[1], convertedSecond[1], percentage));
  const b = Math.round(lerp(converted[2], convertedSecond[2], percentage));

  return rgbToHex(r, g, b);
};

export const isLightColor = (color: string): boolean => {
  const [r, g, b] = hexToRgb(color) || [];

  return r * 0.299 + g * 0.587 + b * 0.114 > 186;
};

export const getBlackOrWhiteFromColor = (color: string): string => {
  if (isLightColor(color)) {
    return '#000000';
  }

  return '#ffffff';
};

export const invertColor = (color: string): string => {
  const [r, g, b] = hexToRgb(color)?.map(item => 255 - item) || [];
  return rgbToHex(r, g, b);
};

export const getTheme = (): typeof theme => {
  const primaryMeta = document
    .querySelector('meta[name="theme-color"]')
    ?.getAttribute('content');

  const darkMeta = document
    .querySelector('meta[name="theme-mode"]')
    ?.getAttribute('content');

  const dark = ['light', 'dark'].includes(darkMeta || '')
    ? darkMeta === 'dark'
    : false;

  const theme = dark ? darkTheme : lightTheme;

  const primary = primaryMeta?.startsWith('#')
    ? primaryMeta
    : theme.palette.primary;

  return {
    ...theme,
    palette: {
      ...theme.palette,
      primary,
      primary_light: mergeColors(primary, '#ffffff', 0.4),
      primary_transparency: `${primary}80`,
    },
  };
};

export const getAnimationUrl = (): string => {
  const content = getMetaTag('loader');
  return content?.startsWith('/') ? content : '/loaders/hamburger.json';
};
