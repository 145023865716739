import styled, { DefaultTheme } from 'styled-components';

interface IContainer {
  color?: keyof DefaultTheme['palette'];
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-color: ${({ theme, color }) =>
      theme.palette[color || 'primary']} !important;
  }
`;
