import { CustomPizzaChargeCheckForKeys } from '../enum/Pizza';
import { ICustomPizzaCharge } from '../models/IPizza';

interface IGetPizzaChargeInfo {
  [CustomPizzaChargeCheckForKeys.FLAVORS]: number;
}

export function getPizzaCharge(
  { defaultCharge, rules }: ICustomPizzaCharge,
  info: IGetPizzaChargeInfo,
): ICustomPizzaCharge['defaultCharge'] {
  return (
    rules
      .sort((a, b) =>
        a.minAmount > b.minAmount ? 1 : a.minAmount < b.minAmount ? -1 : 0,
      )
      .find((option, index) => {
        if (info.FLAVORS >= option.minAmount) {
          const next = rules[index + 1];

          if (next && info.FLAVORS >= next?.minAmount) {
            return false;
          }

          return true;
        }

        return false;
      })?.charge || defaultCharge
  );
}
