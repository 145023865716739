import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import * as cssUtils from './utils/css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { resizeObserverPolyfill } from './utils/polyfills';

serviceWorker.unregister();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  enabled: !['development', 'staging'].includes(process.env.NODE_ENV),
});

cssUtils.initialize();

resizeObserverPolyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
