export const getSubdomain = (): string => {
  const hostname = window.location.hostname.toLowerCase();
  const hostnameSections = hostname.split('.');

  if (
    hostnameSections[0] === '192' ||
    hostnameSections[0] === '127' ||
    hostnameSections[0] === 'www' ||
    hostnameSections[0] === 'auth' ||
    hostnameSections[0] === 'bsfood' ||
    hostnameSections[0] === 'bsvarejo' ||
    hostnameSections[0] === 'staging' ||
    hostnameSections[0] === 'bsfood-dev' ||
    hostnameSections[0] === 'bs-food-menu-staging' ||
    hostnameSections[0] === 'localhost'
  ) {
    return process.env.REACT_APP_DEFAULT_SUBDOMAIN || '';
  }

  return hostnameSections[0];
};

export const isBsVarejo = (): boolean => {
  const { hostname } = window.location;
  return hostname.includes('bsvarejo') && !hostname.includes('bsfood');
};

export const chooseBsFoodOrVarejo = <T>(
  bsFoodOption: T,
  bsVarejoOption: T,
): T => (isBsVarejo() ? bsVarejoOption : bsFoodOption);
