import React, { createContext, useContext, useMemo } from 'react';
import { StylesConfig, defaultTheme } from 'react-select';

import { useTheme } from 'styled-components';

import color from 'color';

interface ISelectStyles {
  selectStyles: StylesConfig<any, any, any>;
}

const context = createContext<ISelectStyles>({} as ISelectStyles);

export const useSelectStyles = (): ISelectStyles => useContext(context);

export const SelectStyledProvider: React.FC = ({ children }) => {
  const theme = useTheme();

  const selectStyles = useMemo<StylesConfig<any, any, any>>(() => {
    return {
      ...defaultTheme,
      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
      option: (provided: any, state: { isSelected: any }) => ({
        ...provided,
        color: theme.palette.black,
        padding: 20,
        backgroundColor: state.isSelected
          ? color(theme.palette.primary).alpha(0.35).rgb().string()
          : theme.palette.background,
        '&:hover': {
          backgroundColor: color(theme.palette.primary)
            .alpha(0.22)
            .rgb()
            .string(),
        },
      }),
      control: (provided: any, { isDisabled }) => ({
        ...provided,
        border: `1px solid ${theme.palette.control}`,
        boxShadow: 0,
        opacity: isDisabled ? 0.5 : 1,
        '&:focus-within': {
          border: `1px solid ${theme.palette.primary}`,
        },
      }),
    };
  }, [theme]);

  return (
    <context.Provider value={{ selectStyles }}>{children}</context.Provider>
  );
};
