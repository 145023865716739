import { randomInt } from './numbers';

type Storages = keyof Pick<typeof window, 'localStorage' | 'sessionStorage'>;

export const isStorageAvailable = (storage: Storages): boolean => {
  try {
    const key = (Date.now() + randomInt(1000, 9999)).toString();
    window[storage].setItem(key, 'test');
    window[storage].getItem(key);
    window[storage].removeItem(key);
    return true;
  } catch {
    return false;
  }
};
