import { createGlobalStyle } from 'styled-components';
import { mergeColors } from '../utils/theme';

export default createGlobalStyle`
  :root {
    --modal-backdrop: ${({ theme }) => theme.palette.modal_backdrop};
    --window-height: 100vh;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', serif;
    -webkit-tap-highlight-color: transparent;
    color: ${({ theme }) => theme.palette.black};
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.palette.background};
  }

  body {
    -webkit-font-smoothing: antialiased;
    color: ${({ theme }) => theme.palette.black};
  }
  body, input, button, textarea {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  .button-facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    min-height: 60px;
    margin-top: 32px;
    border: 0;
    border-radius: 32px 0 32px 32px;
    background: ${({ theme }) => theme.palette.facebook};
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.4s;
    cursor: pointer;

    font-size: 16px;

    color: ${({ theme }) => theme.palette.white};

    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
    }

    svg {
      margin-right: 16px;
    }
  }

  @media(min-width: 1000px) {
    ::-webkit-scrollbar {
      width: 6px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.scrollBar.track};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.scrollBar.thumb};
    }

    :-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.palette.scrollBar.thumb_hover};
    }
  }

  .has-custom-scroll-bar2 {
    @media(min-width: 1000px) {
      &:hover {
        &::-webkit-scrollbar-thumb {
          border: 12px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          background-color: rgba(0, 0, 0, 0.1);
          box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
            inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          border-radius: 24px;
        }
      }
      &::-webkit-scrollbar {
        width: 28px;
      }
      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: transparent;
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }

  ::-moz-selection {
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) =>
      mergeColors(theme.palette.primary, '#ffffff', 0.5)};
  }

  ::selection {
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) =>
      mergeColors(theme.palette.primary, '#ffffff', 0.5)};
  }

  .svg-primary {
    fill: ${({ theme }) => theme.palette.primary};
  }

  .ReactModal__Content {
    @media(max-width: 520px) {
      width: 100%;
      height: 100%;
    }
  }

  .highlightElement {
    animation: highlightElement 2s 1 ease 600ms;
  }

  @keyframes highlightElement {
    30%, 70% {
      box-shadow: 0 0 4px 2px ${({ theme }) => theme.palette.primary};
    };

    0%, 100% {
      box-shadow: none;
    };
  }

  /* html {
    filter: invert(1) hue-rotate(180deg);
  }

  html img {
    filter: invert(1) hue-rotate(180deg);
  } */

`;
