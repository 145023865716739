export enum SessionStorageKeys {
  ORDER_DATA = '@BSFOOD:orderData',
  CART_ID = '@BSFOOD:cartId',
  CHUNK_ERROR_WAS_RELOADED = '@BSFOOD:chunkErrorWasReloaded',
}

export enum LocalStorageKeys {
  COOKIES_ACCEPTED = '@BSFOOD:cookiesAccepted',
  LAST_ADDRESS = '@BSFOOD:lastAddress',
  LAST_CUSTOMER_INFO = '@BSFOOD:lastCustomerInfo',
}
