import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import ICheckCouponDTO from '../dtos/ICheckCouponDTO';
import IDiscountCoupon from '../models/IDiscountCoupon';

import { useCart } from './cart';
import { useToast } from './toast';
import { useCompany } from './company';

import api from '../services/api';
import * as cartOrderData from '../stores/cartOrderData';

interface CouponContextData {
  coupon: IDiscountCoupon | null;
  validateCoupon: (data: ICheckCouponDTO) => Promise<void>;
  clearCoupon: () => void;
}

const CouponContext = createContext<CouponContextData>({} as CouponContextData);

export const CouponProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { company } = useCompany();
  const { cartTotal, ignoreCouponUpdate, changeIgnoreCouponUpdate } = useCart();

  const [oldCartTotal, setOldCartTotal] = useState(0);
  const [coupon, setCoupon] = useState<IDiscountCoupon | null>(null);

  const shouldCheck = useMemo(() => {
    return oldCartTotal !== cartTotal && !!coupon;
  }, [cartTotal, coupon, oldCartTotal]);

  const validateCoupon = useCallback(
    async (data: ICheckCouponDTO) => {
      changeIgnoreCouponUpdate();
      setOldCartTotal(data.total);

      try {
        const response = await api.post(
          `/discounts-coupons/check-availability/${company.id}`,
          data,
        );

        if (response.data.success) {
          setCoupon(response.data.data);
        } else {
          addToast({
            type: 'error',
            description: response.data.error,
          });

          setCoupon(null);
          cartOrderData.clearValue('coupon');
        }
      } catch {
        addToast({
          type: 'error',
          description: 'Cupom inválido.',
        });

        cartOrderData.clearValue('coupon');
        setCoupon(null);
      }
    },
    [company, addToast, changeIgnoreCouponUpdate],
  );

  const checkCoupon = useCallback(async () => {
    setOldCartTotal(cartTotal);

    try {
      const response = await api.post(
        `/discounts-coupons/check-availability/${company.id}`,
        {
          total: cartTotal,
          reference: coupon?.ref,
        },
      );

      if (response.data.success) {
        setCoupon(response.data.data);
      } else {
        addToast({
          type: 'error',
          description: response.data.error,
        });

        cartOrderData.clearValue('coupon');
        setCoupon(null);
      }
    } catch {
      cartOrderData.clearValue('coupon');
      setCoupon(null);

      if (cartTotal > 0) {
        addToast({
          type: 'error',
          description: 'O cupom não é mais válido para essa compra.',
        });
      }
    }
  }, [cartTotal, coupon, company, addToast]);

  const clearCoupon = useCallback(() => {
    cartOrderData.clearValue('coupon');
    setCoupon(null);
  }, []);

  useEffect(() => {
    if (shouldCheck && !ignoreCouponUpdate) {
      checkCoupon();
    }
  }, [shouldCheck, ignoreCouponUpdate, checkCoupon]);

  return (
    <CouponContext.Provider value={{ coupon, validateCoupon, clearCoupon }}>
      {children}
    </CouponContext.Provider>
  );
};

export function useCoupon(): CouponContextData {
  const context = useContext(CouponContext);

  if (!context) {
    throw new Error('useCoupon must be within CouponProvider');
  }

  return context;
}
