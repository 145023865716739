const fqb: FBQ = function customFBQ(...args): void {
  if (window?.fbq) {
    window.fbq(...args);
  }
};

/**
 * @description Used for track when user adds a product to cart
 */
function addToCart(): void {
  fqb('track', 'AddToCart');
}

/**
 * @description Used for track when the user wants to finish a purchase
 */
function initiateCheckout(): void {
  fqb('track', 'InitiateCheckout');
}

/**
 * @description Used when the user adds a payment method
 */
function addPaymentInfo(): void {
  fqb('track', 'AddPaymentInfo');
}

/**
 * @description Used for track when purchase is completed
 */
function purchase(value: number): void {
  fqb('track', 'Purchase', { value, currency: 'BRL' });
}

const FbPixel = {
  event: {
    addToCart,
    initiateCheckout,
    addPaymentInfo,
    purchase,
  },
};

export default FbPixel;
