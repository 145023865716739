import IAddress from '../models/IAddress';
import { ICustomerInfo } from '../pages/FinishPage';

interface IOrderData {
  deliveryType: string;
  paymentType: string;
  address: number;
  document: string;
  obs: string;
  coupon: string;
  unverifiedAddress: IAddress;
  customerName: string;
  customerInfo: ICustomerInfo;
  cardBrandId: number;
}

const sessionKey = '@BSFOOD:cartOrderData';

const getData = () => {
  try {
    const data = JSON.parse(
      sessionStorage.getItem(sessionKey) || '{}',
    ) as IOrderData;
    return data || ({} as IOrderData);
  } catch {
    return {} as IOrderData;
  }
};

export const setValue = (
  key: keyof IOrderData,
  value: IOrderData[typeof key],
): void => {
  sessionStorage.setItem(
    sessionKey,
    JSON.stringify({ ...getData(), [key]: value }),
  );
};

export const clearValue = (key: keyof IOrderData): void => {
  const data = getData();

  delete data[key];

  sessionStorage.setItem(sessionKey, JSON.stringify(data));
};

export const getValue = (
  key: keyof IOrderData,
): IOrderData[typeof key] | undefined => {
  return getData()[key];
};

export const clear = (): void => {
  sessionStorage.removeItem(sessionKey);
};
