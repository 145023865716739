export const objectToQueryParams = (
  object: Record<string, unknown>,
): string => {
  return Object.keys(object)
    .reduce((result, key) => {
      const value = object[key];

      if (value) {
        return [...result, `${key}=${value}`];
      }

      return result;
    }, [] as string[])
    .join('&');
};
