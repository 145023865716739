import React, { createContext, useCallback, useContext, useState } from 'react';

import { IProductGroup } from '../models/IProductGroups';
import api from '../services/api';

interface IProductGroupsContext {
  productGroups?: IProductGroup[];
  loadProductGroups: () => Promise<void>;
}

const ProductGroupsContext = createContext<IProductGroupsContext>(
  {} as IProductGroupsContext,
);

export const ProductGroupsProvider: React.FC = ({ children }) => {
  const [productGroups, setProductGroups] = useState<IProductGroup[]>([]);

  const loadProductGroups = useCallback(async () => {
    const { data } = await api.get<IProductGroup[]>('/product-groups');
    return setProductGroups(data);
  }, []);

  return (
    <ProductGroupsContext.Provider
      value={{
        productGroups,
        loadProductGroups,
      }}
    >
      {children}
    </ProductGroupsContext.Provider>
  );
};

export function useProductGroups(): IProductGroupsContext {
  const context = useContext(ProductGroupsContext);

  if (!context) {
    throw new Error(
      'useProductGroups must be used within ProductGroupsProvider',
    );
  }

  return context;
}
