import React, {
  useState,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from 'react';

import IOrder, { IOrderStatus } from '../models/IOrder';
import IAddress from '../models/IAddress';
import ICustomer from '../models/ICustomer';

import { ICustomerDTO } from '../dtos/ICustomerDTO';
import ISaveAddressDTO from '../dtos/ISaveAddressDTO';
import IDistanceResponseDTO from '../dtos/IDistanceResponseDTO';

import api from '../services/api';
import { useCompany } from './company';

interface AuthContextData {
  loading: boolean;
  isAuthed: boolean;
  myOrders: IOrder[];
  deliveryFee: number;
  addresses: IAddress[];
  selectedAddress: IAddress;
  customer: ICustomer | null;
  deliveryAvailable: boolean;
  selectedOrder: IOrder | null;
  signOut: () => void;
  updateSelectedOrder: () => void;
  refreshUser: () => Promise<void>;
  loadMyOrders: () => Promise<void>;
  loadSelectedOrder: () => Promise<void>;
  loadCustomerAddresses: () => Promise<void>;
  selectOrder: (order: IOrder | null) => void;
  chooseAddress: (address?: IAddress) => void;
  deleteAddress: (id: number) => Promise<void>;
  confirmToken: (token: string) => Promise<void>;
  chooseRegionDeliveryFee: (fee: number) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateCustomer: (customer: ICustomerDTO) => void;
  setAddressAsDefault: (id: number) => Promise<void>;
  saveAddress: (address: ISaveAddressDTO) => Promise<void>;
  getUnauthedDeliveryFee: (address: IAddress) => Promise<void>;
  getDistance: (selectedInputAddress: IAddress) => Promise<void>;
}

interface ILoginResponse {
  expiresIn: number;
  accessToken: string;
  userToken: ICustomer;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [myOrders, setMyOrders] = useState<IOrder[]>([]);

  const [data, setData] = useState<ICustomer | null>(() => {
    const customer = localStorage.getItem('@BSFOOD:customer');

    if (customer) {
      const parsedCustomer = JSON.parse(customer) as ICustomer;

      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: `Bearer ${parsedCustomer.accessToken}`,
      };

      return parsedCustomer;
    }

    return null;
  });

  const [selectedAddress, setSelectedAddress] = useState<IAddress>(
    {} as IAddress,
  );

  const [addresses, setAddresses] = useState<IAddress[]>([]);

  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryAvailable, setDeliveryAvailable] = useState(true);

  const [loading, setLoading] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);

  const isAuthed = useMemo(() => {
    return !!data?.id;
  }, [data]);

  const confirmToken = useCallback(async (token: string) => {
    const response = await api.post<ILoginResponse>(
      `/accounts/confirm-token/${token}`,
    );

    localStorage.setItem(
      '@BSFOOD:customer',
      JSON.stringify({
        ...response.data.userToken,
        accessToken: response.data.accessToken,
      }),
    );

    api.defaults.headers = {
      ...api.defaults.headers,
      Authorization: `Bearer ${response.data.accessToken}`,
    };

    setData(response.data.userToken);
  }, []);

  const signOut = useCallback(() => {
    setAddresses([]);
    setSelectedAddress({} as IAddress);
    localStorage.removeItem('@BSFOOD:customer');
    setData(null);
  }, []);

  const refreshUser = useCallback(async () => {
    api.get<ICustomer>('restricted/accounts/me').then(response => {
      setData(response.data);
    });
  }, []);

  const updateCustomer = useCallback(async (customer: ICustomerDTO) => {
    await api.patch<ICustomerDTO>(`/restricted/accounts/change-name`, {
      name: customer.name,
    });

    setData(prevState => {
      localStorage.setItem(
        '@BSFOOD:customer',
        JSON.stringify({
          ...prevState,
          name: customer.name,
        }),
      );

      return {
        id: prevState?.id || '',
        name: customer.name,
        accessToken: prevState?.accessToken || '',
        email: prevState?.email || '',
        firstAccess: false,
      };
    });
  }, []);

  // const changeName = useCallback(async (name: string) => {
  //   const response = await api.patch(`/restricted/accounts/change-name`);

  //   setData(prevState => prevState. {
  //     return {
  //       ...prevState,

  //       name,
  //     };
  //   });
  // }, []);

  const updatePhoneNumber = useCallback((phoneNumber: string) => {
    const customer = localStorage.getItem('@BSFOOD:customer');

    if (customer) {
      const parsedCustomer = JSON.parse(customer) as ICustomer;
      Object.assign(parsedCustomer, { phoneNumber });

      localStorage.setItem('@BSFOOD:customer', JSON.stringify(parsedCustomer));

      setData(parsedCustomer);
    }
  }, []);

  const chooseAddress = useCallback((address?: IAddress) => {
    if (address) {
      setSelectedAddress(address);
    }
  }, []);

  const loadCustomerAddresses = useCallback(async () => {
    const response = await api.get<IAddress[]>('addresses');

    if ((response?.data?.length || 0) > 0) {
      setAddresses(response.data);
      setSelectedAddress(prevState => {
        if (prevState.streetName) {
          return (
            response.data.find(a => a.id === prevState.id) || response.data[0]
          );
        }

        return (
          response.data.find(address => address.isDefault) || response.data[0]
        );
      });
    }
  }, []);

  const saveAddress = useCallback(
    async (address: ISaveAddressDTO) => {
      if (address.id > 0) {
        const response = await api.put<IAddress>(`/addresses/${address.id}`, {
          ...address,
          streetNumber: address.streetNumber || 'S/N',
        });

        setAddresses([
          response.data,
          ...addresses.filter(a => a.id !== address.id),
        ]);
        setSelectedAddress(response.data);
      } else {
        const response = await api.post<IAddress>('/addresses', {
          ...address,
          streetNumber: address.streetNumber || 'S/N',
        });

        if (response.data.phoneNumber) {
          updatePhoneNumber(response.data.phoneNumber);
        }

        setAddresses([...addresses, response.data]);
        setSelectedAddress(response.data);
      }
    },
    [addresses, updatePhoneNumber],
  );

  const setAddressAsDefault = useCallback(
    async (id: number) => {
      await api.patch(`/addresses/${id}/set-as-default`);

      setAddresses(prevState => {
        return prevState.map(address => {
          return {
            ...address,
            isDefault: address.id === id,
          };
        });
      });

      setSelectedAddress(prevState => {
        return addresses.find(address => address.id === id) || prevState;
      });
    },
    [addresses],
  );

  const deleteAddress = useCallback(
    async (id: number) => {
      const response = await api.delete<IAddress>(`/addresses/${id}`);

      setSelectedAddress(prevState =>
        prevState.id === id ? addresses[0] : prevState,
      );

      setAddresses(prevState => {
        return prevState
          .filter(a => a.id !== id)
          .map(a => {
            return {
              ...a,
              isDefault: response.data
                ? a.id === response.data.id
                : a.isDefault,
            };
          });
      });
    },
    [addresses],
  );

  const loadMyOrders = useCallback(async () => {
    if (company.id) {
      const response = await api.get<IOrder[]>('orders');
      setMyOrders(response.data);
      setSelectedOrder(response.data[0]);
    }
  }, [company]);

  const getDistance = useCallback(async (selectedInputAddress: IAddress) => {
    setLoading(true);

    try {
      if (selectedInputAddress.id) {
        const response = await api.get<IDistanceResponseDTO>(
          `addresses/delivery-fee/${selectedInputAddress.id}`,
        );

        setDeliveryFee(response.data.fee || 0);
        setDeliveryAvailable(response.data.available);
      }
    } catch {
      setDeliveryAvailable(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadSelectedOrder = useCallback(async () => {
    if (
      selectedOrder &&
      selectedOrder.status !== 'CANCELED' &&
      selectedOrder.status !== 'COMPLETED'
    ) {
      const response = await api.get<IOrder>(`/orders/me/${selectedOrder.id}`);
      setMyOrders(prevState => {
        const index = prevState.findIndex(o => o.id === selectedOrder.id);

        if (index > -1) {
          const newState = [...prevState];
          newState[index] = response.data;
          return newState;
        }

        return prevState;
      });

      setSelectedOrder(response.data);
    }
  }, [selectedOrder]);

  const handleUpdateSelectedOrder = useCallback(async () => {
    if (selectedOrder) {
      const response = await api.get<IOrderStatus>(
        `/orders/me/${selectedOrder.id}/status`,
      );
      const newItem = { ...selectedOrder, ...response.data };

      setMyOrders(prevState =>
        prevState.map(item => (item.id === selectedOrder.id ? newItem : item)),
      );

      setSelectedOrder(newItem);
    }
  }, [selectedOrder]);

  const getUnauthedDeliveryFee = useCallback(async (address: IAddress) => {
    setLoading(true);

    try {
      const response = await api.post<IDistanceResponseDTO>(
        'addresses/delivery-fee/unauthed',
        address,
      );

      setDeliveryFee(response.data.fee || 0);
      setDeliveryAvailable(response.data.available);
    } catch {
      setDeliveryAvailable(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const selectOrder = useCallback((order: IOrder | null) => {
    setSelectedOrder(order);
  }, []);

  const chooseRegionDeliveryFee = useCallback((fee: number) => {
    setDeliveryFee(fee);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthed,
        myOrders,
        addresses,
        deliveryFee,
        selectedOrder,
        customer: data,
        selectedAddress,
        deliveryAvailable,
        signOut,
        getDistance,
        refreshUser,
        saveAddress,
        selectOrder,
        confirmToken,
        loadMyOrders,
        chooseAddress,
        deleteAddress,
        updateCustomer,
        loadSelectedOrder,
        updatePhoneNumber,
        setAddressAsDefault,
        loadCustomerAddresses,
        getUnauthedDeliveryFee,
        chooseRegionDeliveryFee,
        updateSelectedOrder: handleUpdateSelectedOrder,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}
