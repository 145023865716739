/* eslint-disable no-return-assign */
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import { useHistory } from 'react-router-dom';

import ICart from '../models/ICart';
import IAddToCartDTO from '../dtos/IAddToCartDTO';

import { useToast } from './toast';
import { useCompany } from './company';

import IProduct from '../models/IProduct';

import api from '../services/api';
import { PizzaComplementsGroupIds } from '../enum/Pizza';
import FbPixel from '../services/fbq';

interface CartContextData {
  cart: ICart[];
  cartTotal: number;
  cartAmount: number;
  suggestions: IProduct[];
  ignoreCouponUpdate: boolean;
  clearCart: () => void;
  increaseAmount: (id: string) => void;
  decreaseAmount: (id: string) => void;
  removeFromCart: (id: string) => void;
  changeIgnoreCouponUpdate: () => void;
  addMultipleToCart: (items: IAddToCartDTO[]) => void;
  addToCart: (item: IAddToCartDTO, quick?: boolean) => void;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

export const CartProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const { addToast } = useToast();
  const { company, isOpen } = useCompany();

  const [data, setData] = useState<ICart[]>([]);
  const [suggestions, setSuggestions] = useState<IProduct[]>([]);
  const [ignoreCouponUpdate, setIgnoreCouponUpdate] = useState(false);

  const cartAmount = useMemo(() => {
    return data.reduce((sum, current) => {
      return sum + current.qty;
    }, 0);
  }, [data]);

  useEffect(() => {
    if (company.id > 0) {
      const storagedCart = sessionStorage.getItem('@BSFOOD:cart');

      if (storagedCart) {
        const cart = JSON.parse(storagedCart) as ICart[];
        setData(cart.filter(item => item.companyId === company.id));
      }
    }
  }, [company]);

  const addToCart = useCallback(
    (item: IAddToCartDTO, quick?: boolean) => {
      if (company && !isOpen) {
        addToast({
          type: 'error',
          description: 'A empresa encontra-se fechada.',
        });

        return;
      }

      if (quick) {
        const index = data.findIndex(
          i =>
            i.productId === item.productId &&
            item.complementsGroups.length <= 0 &&
            !item.comments,
        );

        if (index > -1) {
          const newData = [...data];
          newData[index] = item;
          setData(newData);
          sessionStorage.setItem('@BSFOOD:cart', JSON.stringify(newData));
        } else {
          setData([...data, item]);
          sessionStorage.setItem(
            '@BSFOOD:cart',
            JSON.stringify([...data, item]),
          );
        }
      } else {
        const index = data.findIndex(p => p.id === item.id);

        if (index > -1) {
          const newData = [...data];
          newData[index] = item;
          setData(newData);
        } else {
          setData([...data, item]);
          sessionStorage.setItem(
            '@BSFOOD:cart',
            JSON.stringify([...data, item]),
          );
        }
      }

      FbPixel.event.addToCart();
    },
    [data, company, isOpen, addToast],
  );

  const addMultipleToCart = useCallback(
    (items: IAddToCartDTO[]) => {
      setData([...data, ...items]);

      sessionStorage.setItem(
        '@BSFOOD:cart',
        JSON.stringify([...data, ...items]),
      );

      FbPixel.event.addToCart();
    },
    [data],
  );

  const removeFromCart = useCallback(
    (id: string) => {
      const shouldNavigateBack = data.length === 1;

      if (data) {
        const newData = data.filter(item => item.id !== id);
        setData(newData);
        sessionStorage.setItem('@BSFOOD:cart', JSON.stringify(newData));

        if (shouldNavigateBack) {
          history.push('/');
        }
      }
    },
    [data, history],
  );

  const clearCart = useCallback(() => {
    setIgnoreCouponUpdate(true);

    setData([]);
    sessionStorage.removeItem('@BSFOOD:cart');
  }, []);

  const handleLoadSuggestions = useCallback(async (items: ICart[]) => {
    if (items.length > 0) {
      let productIds: number[] = [];

      items.forEach(item => {
        if (item?.isCustomPizza) {
          const flavorGroup = item?.complementsGroups?.find(
            group => group?.id === PizzaComplementsGroupIds.FLAVORS,
          );
          const flavorsIds: number[] = [];

          flavorGroup?.complements.forEach(complement => {
            if (complement?.productId) {
              flavorsIds.push(complement?.productId);
            }
          });

          productIds = productIds.concat(flavorsIds);
        } else {
          productIds.push(item?.productId);
        }
      });
      const query = productIds.join('&ProductIds=');

      const response = await api.get<IProduct[]>(
        `/products/suggestions/?ProductIds=${query}`,
      );

      setSuggestions(response.data || []);
    }
  }, []);

  const increaseAmount = useCallback(
    (id: string) => {
      const index = data.findIndex(i => i.id === id);

      if (index > -1) {
        const newData = [...data];

        newData[index].qty += 1;

        setData(newData);
        sessionStorage.setItem('@BSFOOD:cart', JSON.stringify(newData));
      }
    },
    [data],
  );

  const decreaseAmount = useCallback(
    (id: string) => {
      const index = data.findIndex(i => i.id === id);

      if (index > -1) {
        const newData = [...data];

        newData[index].qty -= 1;

        setData(newData);
        sessionStorage.setItem('@BSFOOD:cart', JSON.stringify(newData));
      }
    },
    [data],
  );

  const changeIgnoreCouponUpdate = useCallback(() => {
    setIgnoreCouponUpdate(false);
  }, []);

  const cartTotal = useMemo(() => {
    return data.reduce((sum, item) => {
      if (item.isCustomPizza) return sum + item.unitPrice * item?.qty;
      return (
        sum +
        (!item.pizzaCategoryId ? item.unitPrice : 0) * item.qty +
        item.complementsGroups.reduce((sum2, groups) => {
          if (item.isCustomPizza) return 0;
          return (
            sum2 +
            groups.complements.reduce((sum3, complement) => {
              return sum3 + complement.amount * complement.unitPrice * item.qty;
            }, 0)
          );
        }, 0)
      );
    }, 0);
  }, [data]);

  useEffect(() => {
    if (data) {
      handleLoadSuggestions(data);
    } else {
      setSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLoadSuggestions, data?.length]);

  return (
    <CartContext.Provider
      value={{
        cart: data,
        cartTotal,
        cartAmount,
        suggestions,
        ignoreCouponUpdate,
        addToCart,
        clearCart,
        increaseAmount,
        decreaseAmount,
        removeFromCart,
        addMultipleToCart,
        changeIgnoreCouponUpdate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within CartProvider');
  }

  return context;
}
