import { getMetaContent } from '@sentry/tracing/dist/browser/browsertracing';
import React, { createContext, useCallback, useState, useContext } from 'react';

import IProduct, { IProductPizzaSize } from '../models/IProduct';

import api from '../services/api';

interface ProductsContextData {
  loading: boolean;
  products: IProduct[];
  highlights: IProduct[];
  topProducts: IProduct[];
  discountProducts: IProduct[];
  selectedProduct?: number | null;
  selectedPizzaCategory?: number | null;
  selectedPizzaSize?: IProductPizzaSize | null;
  setSelectedProduct: (productId: number | null) => void;
  setSelectedPizzaCategory: (categoryId: number | null) => void;
  setSelectedPizzaSize: (size: IProductPizzaSize | null) => void;
  loadProducts(table: boolean, category?: number): Promise<void>;
  loadHighlights(table: boolean): Promise<void>;
}

const ProductsContext = createContext<ProductsContextData>(
  {} as ProductsContextData,
);

export const ProductsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IProduct[]>([]);
  const [highlights, setHighlights] = useState<IProduct[]>([]);

  const [topProducts, setTopProducts] = useState<IProduct[]>([]);
  const [discountProducts, setDiscountProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [selectedPizzaCategory, setSelectedPizzaCategory] = useState<
    number | null
  >(null);
  const [
    selectedPizzaSize,
    setSelectedPizzaSize,
  ] = useState<IProductPizzaSize | null>(null);

  const loadHighlights = useCallback(async (table: boolean) => {
    let response: IProduct[] = [];

    try {
      const modules = table ? 'TABLE' : 'DELIVERY';
      const apiResponse = await api.get<IProduct[]>('/products/highlights', {
        params: { modules },
      });
      response = apiResponse.data || [];
    } finally {
      setHighlights(response || []);
    }
  }, []);

  const loadProducts = useCallback(
    async (table: boolean, category?: number) => {
      const foundCompany = getMetaContent('bsfood-found-company') !== 'false';

      if (foundCompany) {
        try {
          setLoading(true);
          const modules = table ? 'TABLE' : 'DELIVERY';

          const response = await api.get<IProduct[]>('/products', {
            params: {
              active: true,
              modules,
              ...(category && category !== -2 && { categoryId: category }),
            },
          });

          const top: IProduct[] = [];
          const normal: IProduct[] = [];
          const discount: IProduct[] = [];

          response.data.forEach(item => {
            const normalizedItem: typeof item = {
              ...item,
              complementsGroups: item.complementsGroups.filter(
                group =>
                  group?.complements?.length > 0 &&
                  (group?.modules
                    ? group?.modules?.includes(table ? 'TABLE' : 'DELIVERY')
                    : true),
              ),
              unitPrice:
                table && item?.onTablePriceEnabled
                  ? item?.onTableUnitPrice || item?.unitPrice
                  : item?.unitPrice,
              suggestedPrice:
                table && item?.onTablePriceEnabled
                  ? item?.onTableSuggestedPrice || item?.suggestedPrice
                  : item?.suggestedPrice,
              suggestedSalePrice:
                table && item?.onTablePriceEnabled
                  ? item?.onTableSuggestedSalePrice || item?.suggestedSalePrice
                  : item?.suggestedSalePrice,
            };

            if (
              normalizedItem.categories
                .map(pCategory => pCategory.id)
                .includes(-1)
            ) {
              top.push(normalizedItem);
              normal.push({
                ...normalizedItem,
                categories: normalizedItem.categories.filter(c => c.id !== -1),
              });
            } else {
              normal.push(normalizedItem);
            }

            if (normalizedItem.salePrice) {
              discount.push(normalizedItem);
            }
          });

          setData(normal);
          setDiscountProducts(discount);
          setTopProducts(top);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [],
  );

  const handleSetSelectedProduct = useCallback((product: number | null) => {
    setSelectedProduct(product);
  }, []);

  const handleSetSelectedPizzaCategory = (id: number | null) =>
    setSelectedPizzaCategory(id);

  const handleSetSelectedPizzaSize = (size: IProductPizzaSize | null) =>
    setSelectedPizzaSize(size);

  return (
    <ProductsContext.Provider
      value={{
        loading,
        products: data,
        highlights,
        topProducts,
        loadProducts,
        loadHighlights,
        selectedProduct,
        discountProducts,
        selectedPizzaCategory,
        selectedPizzaSize,
        setSelectedProduct: handleSetSelectedProduct,
        setSelectedPizzaCategory: handleSetSelectedPizzaCategory,
        setSelectedPizzaSize: handleSetSelectedPizzaSize,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export function useProducts(): ProductsContextData {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('useProducts must be used within ProductsProvider');
  }

  return context;
}
