import { v4 as uuid } from 'uuid';
import { PizzaCharges, PizzaComplementsGroupIds } from '../enum/Pizza';
import { SessionStorageKeys } from '../enum/Storage';
import ICartItem from '../models/ICartItem';
import ICompany from '../models/ICompany';
import { getPizzaCharge } from './charge';

export const generateCartId = (): void =>
  sessionStorage.setItem(SessionStorageKeys.CART_ID, uuid());

export const clearCartId = (): void =>
  sessionStorage.removeItem(SessionStorageKeys.CART_ID);

export const getCartId = (): string =>
  sessionStorage.getItem(SessionStorageKeys.CART_ID) || '';

export const getCartItemTotal = (
  company: ICompany,
  item: ICartItem,
): number => {
  const flavorsGroup = item.complementsGroups?.find(
    group => group?.id === PizzaComplementsGroupIds?.FLAVORS,
  );

  const pizzaCharge =
    company?.pizzaCharge === PizzaCharges.CUSTOM && company?.pizzaChargeCustom
      ? getPizzaCharge(company.pizzaChargeCustom, {
          FLAVORS: flavorsGroup?.complements?.length || 1,
        })
      : company?.pizzaCharge;

  return (
    (!item.pizzaCategoryId ? item.unitPrice : 0) * item.qty +
    item.complementsGroups.reduce((sum2, groups) => {
      if (
        groups?.id === PizzaComplementsGroupIds.FLAVORS &&
        pizzaCharge === PizzaCharges.GREATER
      ) {
        const greaterComplement = Math.max(
          ...(groups?.complements?.map(flavor => flavor?.unitPrice || 0) || []),
        );

        return greaterComplement * (groups?.totalAmount || 0);
      }

      return (
        sum2 +
        groups.complements.reduce((sum3, complement) => {
          return sum3 + complement.amount * complement.unitPrice * item.qty;
        }, 0)
      );
    }, 0)
  );
};
