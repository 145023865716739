import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  dark: true,
  palette: {
    primary: '#db1622',
    primary_contrast: '#fff',
    primary_light: '#f6adb2',
    primary_transparency: '#db162280',
    secondary: '#c9a331',
    secondary_light: '#fdeaaf',
    white: '#1e1e1f',
    white_transparency: '#fff6',
    green: '#27ae60',
    green_light: '#2ecc71',
    control: '#aaa',
    control_light: '#fdfdfd',
    control_lighter: '#222325',
    info: '#3172b7',
    error: '#c53030',
    error_transparency: '#c5303050',
    success: '#27ae60',
    success_transparency: '#27ae6050',
    badge: '#e74c3c',
    light_text: '#ccc',
    light_text_2: '#b5b5b5',
    black: '#fafafa',
    black_transparency: '#1b1c1e20',
    facebook: '#4c69ba',
    instagram: '#e1306c',
    whatsapp: '#25d366',
    background: '#161618',
    background_white: '#252528',
    background_dark: '#e3dada',
    background_info: '#2a2a2c',
    background_error: '#252528',
    background_success: '#252528',
    background_secondary: '#1d1e20',
    outline: 'rgba(180, 180, 180, .1)',
    scrollBar: {
      track: '#3e3e41',
      thumb: '#828287',
      thumb_hover: '#9a9a9e',
    },
    border: '#2c2c30',
    shimmer_gradient:
      'linear-gradient(90deg,rgba(238, 238, 238, 1) 12%,rgba(210, 210, 210, 1) 50%,rgba(238, 238, 238, 1) 88%)',
    sale_price: '#cad6e3',
    modal_backdrop: 'rgba(0, 0, 0, 0.75)',
    shimmer_background: '#2a2a2d',
    shimmer_color: '#36363a',
  },
  shadow: {
    box_shadow: '3px 3px 8px 0 rgba(0, 0, 0, 0.4)',
    box_shadow_hover: '3px 3px 8px 0 rgba(0, 0, 0, 0.4)',
    box_shadow_large: '6px 6px 24px 0 rgba(0, 0, 0, 0.5)',
    box_shadow_small: '2px 2px 4px 0 rgba(0, 0, 0, 0.2)',
    box_shadow_float: '-1px 2px 3px rgba(0, 0, 0, 0.4)',
    box_shadow_modal: '3px 3px 8px 0 rgb(0 0 0 / 10%)',
  },
};

export default theme;
