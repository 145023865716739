import { getSubdomain, isBsVarejo } from './subdomain';
import { objectToQueryParams } from './uri';

export function redirectToAuth(
  customParams?: Record<string, string | number> | null,
): void {
  const params = {
    subdomain: getSubdomain(),
    ...(!!customParams && customParams),
    redirect:
      typeof customParams?.redirect === 'string'
        ? customParams?.redirect.replace(/\/+/, '')
        : undefined,
    isVarejo: isBsVarejo(),
  };

  window.open(
    `${process.env.REACT_APP_AUTH_URL}?${objectToQueryParams(params)}`,
    '_self',
  );
}
