import React, { createContext, useCallback, useState, useContext } from 'react';

import ICategory from '../models/ICategory';

import api from '../services/api';

interface CategoriesContextData {
  categories: ICategory[];
  loadCategories(table: boolean): Promise<void>;
}

const CategoriesContext = createContext<CategoriesContextData>(
  {} as CategoriesContextData,
);

export const CategoriesProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ICategory[]>([]);

  const loadCategories = useCallback(async (table: boolean) => {
    try {
      const modules = table ? 'TABLE' : 'DELIVERY';

      const response = await api.get<ICategory[]>('/categories', {
        params: {
          active: true,
          modules,
        },
      });
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <CategoriesContext.Provider value={{ categories: data, loadCategories }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export function useCategories(): CategoriesContextData {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategories must be used within CategoriesProvider');
  }

  return context;
}
