import React from 'react';

import { AuthProvider } from './auth';
import { CartProvider } from './cart';
import { OrderProvider } from './order';
import { ToastProvider } from './toast';
import { CouponProvider } from './coupon';
import { CompanyProvider } from './company';
import { ProductsProvider } from './products';
import { CategoriesProvider } from './categories';

import { SelectStyledProvider } from '../styles/select';
import { ProductGroupsProvider } from './product-groups';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <CompanyProvider>
      <AuthProvider>
        <CategoriesProvider>
          <ProductGroupsProvider>
            <ProductsProvider>
              <CartProvider>
                <OrderProvider>
                  <CouponProvider>
                    <SelectStyledProvider>{children}</SelectStyledProvider>
                  </CouponProvider>
                </OrderProvider>
              </CartProvider>
            </ProductsProvider>
          </ProductGroupsProvider>
        </CategoriesProvider>
      </AuthProvider>
    </CompanyProvider>
  </ToastProvider>
);

export default AppProvider;
