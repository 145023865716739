import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';

import Routes from './routes';
import AppProvider from './hooks';
import TableProvider from './providers/table';

import { isStorageAvailable } from './utils/security';
import { getTheme } from './utils/theme';
import { isBrowserSupported } from './utils/browser';
import { LocalStorageKeys } from './enum/Storage';
import * as cartOrderData from './stores/cartOrderData';

import Loading from './components/Loading';

import lazyWithRetry from './utils/react';

const OfflineModal = lazyWithRetry(() => import('./components/OfflineModal'));
const ErrorBoundary = lazyWithRetry(() => import('./components/ErrorBoundary'));

const CookiesUseAlert = lazyWithRetry(() =>
  import('./components/Alert').then(module => ({
    default: module.CookiesUseAlert,
  })),
);
const IncompatibleAlert = lazyWithRetry(() =>
  import('./components/Alert').then(module => ({
    default: module.IncompatibleAlert,
  })),
);
const SecurityAlert = lazyWithRetry(() =>
  import('./components/Alert').then(module => ({
    default: module.SecurityAlert,
  })),
);

const isAllStoragesAvailable =
  isStorageAvailable('localStorage') && isStorageAvailable('sessionStorage');

const theme = getTheme();

const App: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    window.location.pathname === '/privacy-policy'
      ? 'true'
      : localStorage?.getItem(LocalStorageKeys.COOKIES_ACCEPTED) || '',
  );

  const handleOnCookiesAccept = () => {
    setCookiesAccepted('true');
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const couponRef = params.get('coupon');

    if (couponRef) {
      cartOrderData.setValue('coupon', couponRef);
    }
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<Loading radius={82} />}>
          <GlobalStyle />
          {!isAllStoragesAvailable ? (
            <SecurityAlert />
          ) : !isBrowserSupported() ? (
            <IncompatibleAlert />
          ) : (
            <AppProvider>
              <TableProvider>
                <OfflineModal />
                <ErrorBoundary>
                  {cookiesAccepted !== 'true' && (
                    <CookiesUseAlert onAccept={handleOnCookiesAccept} />
                  )}
                  <Routes />
                </ErrorBoundary>
              </TableProvider>
            </AppProvider>
          )}
        </React.Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
