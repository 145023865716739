import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { useCompany } from '../hooks/company';

import lazyWithRetry from '../utils/react';

const PrivateRoute = lazyWithRetry(() => import('../components/PrivateRoute'));

const ErrorPage = lazyWithRetry(() => import('../pages/ErrorPage'));
const SignInPage = lazyWithRetry(() => import('../pages/SignInPage'));
const ProductsPage = lazyWithRetry(() => import('../pages/ProductsPage'));
const OrderSentPage = lazyWithRetry(() => import('../pages/OrderSentPage'));

const LoadingPage = lazyWithRetry(() => import('../pages/LoadingPage'));
const ServerErrorPage = lazyWithRetry(() => import('../pages/ServerErrorPage'));
const FinishPage = lazyWithRetry(() => import('../pages/FinishPage'));
const ProfilePage = lazyWithRetry(() => import('../pages/ProfilePage'));
const ScanPage = lazyWithRetry(() => import('../pages/ScanPage'));
const LinkPage = lazyWithRetry(() => import('../pages/LinkPage'));
const TablePage = lazyWithRetry(() => import('../pages/TablePage'));
const HistoryPage = lazyWithRetry(() => import('../pages/HistoryPage'));
const PrivacyPolicyPage = lazyWithRetry(
  () => import('../pages/PrivacyPolicyPage'),
);

const Routes: React.FC = () => {
  const { customer } = useAuth();
  const { isShowcase } = useCompany();

  return (
    <Switch>
      <Route path="/" component={ProductsPage} exact />
      <PrivateRoute
        path="/cart"
        redirect="/"
        authed={!isShowcase}
        component={ProductsPage}
      />
      <Route path="/filter" component={ProductsPage} />
      <Route path="/product" component={ProductsPage} />
      <Route path="/category" component={ProductsPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/scan" component={ScanPage} />
      <Route path="/hash" component={LoadingPage} />
      <Route path="/cards/hash" component={LoadingPage} />
      <Route path="/loading" component={LoadingPage} />
      <Route path="/signin" component={SignInPage} />
      <Route path="/session-expired" component={SignInPage} />
      <Route path="/server-error" component={ServerErrorPage} />
      <Route path="/finish" component={FinishPage} />
      <PrivateRoute
        authed={!!customer}
        path="/me"
        component={ProfilePage}
        redirect="/signin"
      />
      <Route path="/order-sent" component={OrderSentPage} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/links" component={LinkPage} />
      <Route path="/about-us" component={HistoryPage} />
      <Route path="/table" component={TablePage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
