export enum PizzaComplementsGroupIds {
  FLAVORS = -8,
  CRUSTS = -9,
  EDGES = -10,
}

export enum PizzaCharges {
  AVERAGE = 'AVERAGE',
  GREATER = 'GREATER',
  CUSTOM = 'CUSTOM',
}

export enum CustomPizzaChargeCheckForKeys {
  FLAVORS = 'FLAVORS',
}
